
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import PageAnalytics from '../../../atomic/organisms/Page-Analytics/Page-Analyitics.vue';
import Table from '../../../atomic/organisms/Table/Table.vue';
import NavTabs from '../../../atomic/atoms/Nav-Tabs/Nav-Tabs.vue';
import PageControls from '../../../atomic/organisms/Page-Controls/Page-Controls.vue';
import { ColumnDefinition, SortDefinition } from '../../../utilities/Types/table.types';
import { Tabs } from '../../../utilities/Types/navigation.types';
import { IDropdownOption } from '../../../utilities/Interfaces/form.interfaces';
import axios from 'axios';

export default defineComponent({
  components: {
    'arc-page-analytics': PageAnalytics,
    'arc-table': Table,
    'arc-nav-tabs': NavTabs,
    'arc-page-controls': PageControls,
  },
  async mounted(): Promise<void> {
    const response = await this.fetchContacts({
      page: this.pageValue.value,
      limit: this.showValue.value,
      search: this.searchFilter,
      sortKey: this.sortDefinition.column,
      sortDirection: this.sortDefinition.direction,
    });
    this.total = response?.total;
  },
  watch: {
    resetPagination: {
      deep: true,
      async handler() {
        this.showValue = { description: '25', value: 25 };
        this.pageValue = { description: '1', value: 1 };
        const response = await this.fetchContacts({
          page: this.pageValue.value,
          limit: this.showValue.value,
          search: this.searchFilter,
          sortKey: this.sortDefinition.column,
          sortDirection: this.sortDefinition.direction,
        });
        this.total = response?.total;
      },
    },
    loadingContacts: {
      handler() {
        this.loading = this.loadingContacts;
      },
    },
  },
  computed: {
    ...mapGetters(['contact', 'contacts', 'access', 'permissions', 'loadingContacts', 'resetPagination']),
    isLoading(): boolean {
      return this.loading || this.loadingContacts;
    },
    columnDefinitions(): ColumnDefinition[] {
      if (this.permissions?.superAdminAccess) {
        return [
          {
            type: 'text',
            columnName: 'firstName',
            displayName: 'First Name',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'lastName',
            displayName: 'Last Name',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'title',
            displayName: 'Title',
          },
          {
            type: 'text',
            columnName: 'phoneNumber',
            displayName: 'Phone Number',
          },
          {
            type: 'text',
            columnName: 'email',
            displayName: 'Email',
          },
          {
            type: 'text',
            columnName: 'aliasEmail',
            displayName: 'Alias Email',
          },
          {
            type: 'urlText',
            columnName: 'mallLandingPageLink',
            displayName: 'Mall Landing Page',
            headerStyle: 'min-width: 150px',
          },
          {
            type: 'text',
            columnName: 'contactTypeDescription',
            displayName: 'Contact Type',
          },
          {
            type: 'text',
            columnName: 'actionType',
            displayName: 'Action Type',
          },
          {
            type: 'text',
            columnName: 'emailType',
            displayName: 'TO/CC',
          },
          {
            type: 'stringArray',
            columnName: 'mallsConnected',
            displayName: 'Malls Connected',
            headerStyle: 'min-width: 300px',
          },
          {
            type: 'stringArray',
            columnName: 'communicationTypesDescriptions',
            displayName: 'Communication Types',
            headerStyle: 'min-width: 300px',
          },
          {
            type: 'stringArray',
            columnName: 'channelDescriptions',
            displayName: 'Channels',
          },
          {
            type: 'date',
            columnName: 'lastCampaignSent',
            displayName: 'Last Campaign Sent',
          },
          {
            type: 'text',
            columnName: 'lastSendgridResponse',
            displayName: 'Last Sendgrid Response',
          },
          {
            type: 'date',
            columnName: 'lastAction',
            displayName: 'Last Action',
          },
          {
            type: 'text',
            columnName: 'last5ApprovalPercentage',
            displayName: 'Last 5 Campaign Approval %',
          },
          {
            type: 'approval',
            columnName: 'stats',
            displayName: 'Approval',
            headerStyle: 'min-width: 525px',
          },
          {
            type: 'stringArray',
            columnName: 'contactIds',
            displayName: 'Contact IDs',
            headerStyle: 'min-width: 300px',
          },
          {
            type: 'text',
            columnName: 'id',
            displayName: 'Contact Group ID',
          },
          {
            type: 'text',
            columnName: 'inSync',
            displayName: 'In Sync (Admin)',
          },
        ];
      }
      return [];
    },
    tabs(): Tabs {
      return [
        {
          title: 'CONTACTS',
          route: 'contacts',
          activeRouteCheck: '/contacts/view',
        },
      ];
    },
    tableData(): unknown {
      return this.contacts;
    },
    tableOptions(): any {
      return {
        clickable: true,
      };
    },
    pageOptions(): IDropdownOption[] {
      let total: any = this.total;
      let showValue: any = this.showValue.value;
      let options = [];
      let numberOfPages = Math.ceil(total / showValue);
      for (let i = 0; i < numberOfPages; i++) {
        options.push({ description: `${i + 1}`, value: i + 1 });
      }
      return options;
    },
    totalPages(): number {
      let total: any = this.total;
      let showValue: any = this.showValue.value;
      return Math.ceil(total / showValue);
    },
  },

  data(): {
    statusFilterValue: IDropdownOption;
    statusFilterOptions: IDropdownOption[];
    searchFilter: string;
    showValue: IDropdownOption;
    showOptions: IDropdownOption[];
    pageValue: IDropdownOption;
    allTableData: any;
    sortDefinition: SortDefinition;
    loading: boolean;
    total: number;
    buttonFeedback: {
      exportLoading: boolean;
      exportSubmitted: boolean;
    };
  } {
    return {
      statusFilterValue: { description: 'All', value: 'all' },
      statusFilterOptions: [
        { description: 'All', value: 'all' },
        { description: 'Active', value: '2' },
        { description: 'Inactive', value: 'inactive' },
        { description: 'Banned', value: 'banned' },
        { description: 'Deleted', value: 'deleted' },
      ],
      searchFilter: '',
      showValue: { description: '25', value: 25 },
      showOptions: [
        { description: '10', value: 10 },
        { description: '25', value: 25 },
        { description: '50', value: 50 },
        { description: '100', value: 100 },
      ],
      pageValue: { description: '1', value: 1 },
      allTableData: [],
      sortDefinition: { column: 'id', type: 'text', direction: 'DESC' },
      loading: true,
      total: 0,
      buttonFeedback: {
        exportLoading: false,
        exportSubmitted: false,
      },
    };
  },

  methods: {
    ...mapActions(['openModal', 'updateModalType', 'updateModalComponent', 'updateModalData', 'fetchContacts', 'exportUsers']),
    startLoading(): void {
      this.loading = true;
    },
    finishLoading(): void {
      this.loading = false;
    },
    resetPageValue(): void {
      this.pageValue = { description: '1', value: 1 };
    },
    updateAllTableData(data: any): void {
      this.allTableData = data;
    },
    updateStatusFilter(filter: IDropdownOption) {
      this.resetPageValue();
      this.statusFilterValue = filter;
    },
    async sort(payload: SortDefinition) {
      this.resetPageValue();
      this.sortDefinition = payload;
      const response = await this.fetchContacts({
        page: this.pageValue.value,
        limit: this.showValue.value,
        search: this.searchFilter,
        sortKey: this.sortDefinition.column,
        sortDirection: this.sortDefinition.direction,
      });
      this.total = response?.total;
    },
    async updateShow(filter: IDropdownOption) {
      this.resetPageValue();
      this.showValue = filter;
      const response = await this.fetchContacts({
        page: this.pageValue.value,
        limit: this.showValue.value,
        search: this.searchFilter,
        sortKey: this.sortDefinition.column,
        sortDirection: this.sortDefinition.direction,
      });
      this.total = response?.total;
    },
    async updatePage(filter: IDropdownOption) {
      this.pageValue = filter;
      const response = await this.fetchContacts({
        page: this.pageValue.value,
        limit: this.showValue.value,
        search: this.searchFilter,
        sortKey: this.sortDefinition.column,
        sortDirection: this.sortDefinition.direction,
      });
      this.total = response?.total;
    },
    async previousPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value - 1}`,
        value: pageValue.value - 1,
      };
      const response = await this.fetchContacts({
        page: this.pageValue.value,
        limit: this.showValue.value,
        search: this.searchFilter,
        sortKey: this.sortDefinition.column,
        sortDirection: this.sortDefinition.direction,
      });
      this.total = response?.total;
    },
    async nextPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value + 1}`,
        value: pageValue.value + 1,
      };
      const response = await this.fetchContacts({
        page: this.pageValue.value,
        limit: this.showValue.value,
        search: this.searchFilter,
        sortKey: this.sortDefinition.column,
        sortDirection: this.sortDefinition.direction,
      });
      this.total = response?.total;
    },
    async search(searchFilter: string): Promise<void> {
      this.searchFilter = searchFilter;
      this.resetPageValue();
      const response = await this.fetchContacts({
        page: this.pageValue.value,
        limit: this.showValue.value,
        search: this.searchFilter,
        sortKey: this.sortDefinition.column,
        sortDirection: this.sortDefinition.direction,
      });
      this.total = response?.total;
    },
    create(): void {
      this.updateModalComponent('contact');
      this.updateModalType('large');
      this.openModal();
    },
    reports(): void {
      this.$router.push('/reports');
    },
    async export(): Promise<void> {
      this.buttonFeedback.exportSubmitted = true;
      this.buttonFeedback.exportLoading = false;
      const URL = `${process.env.VUE_APP_URI}/exports/contacts`;
      this.$notify({
        title: 'Export is in progress.',
        text: `You will receive an email with the required export file attached once it is ready. You can continue working in the app.`,
        type: 'info',
        duration: 12000,
      });
      setTimeout(() => {
        this.buttonFeedback.exportSubmitted = false;
      }, 12000);
      await axios.get(URL, {
        withCredentials: true,
      });
    },
    rowClicked(row: any): void {
      this.updateModalComponent('contact');
      this.updateModalType('large');
      this.updateModalData({ row, mode: 'edit' });
      this.openModal();
    },
    approvalClicked(payload: { status: string; type: string; row: any }): void {
      this.updateModalComponent('approval-contacts');
      this.updateModalType('large');
      this.updateModalData(payload);
      this.openModal();
    },
  },
});
